<template>
  <div class="columns is-gapless">
    <div class="column">
      <p class="title">Detail Info</p>
      <div class="field">
        <label class="label is-required-label">Job Stage </label>
        <div class="control is-extended">
          <div class="select is-fullwidth">
            <select v-model="value.jobStage"
              :class="{ 'input is-danger': $v.entity.jobStage.$error }">
              <option v-for="(s, index) in jobStages"
                :key="index"
                :value="s.stage">
                {{ s.description }}
              </option>
            </select>
          </div>
          <span class="help is-danger"
            v-if="$v.entity && !$v.entity.jobStage.required">Job Stage is required</span>
        </div>
      </div>
      <div class="field">
        <label class="label is-required-label">Sub Job Stage </label>
        <div class="control">
          <input v-model="value.subJobStageName"
            class="input"
            :class="{ 'is-danger': $v.entity.subJobStageName.$error }"
            type="text">
          <span class="help is-danger"
            v-if="$v.entity && !$v.entity.subJobStageName.required">Sub Job Stage is required</span>
        </div>
      </div>
      <div class="field">
        <label class="label is-required-label">Sort Number</label>
        <div class="control">
          <vue-numeric
            class="input has-text-right"
            :precision="0"
            :class="{ 'is-danger': $v.entity.sortKey.$error }"
            v-model.number="value.sortKey" />
        </div>
      </div>
      <div class="buttons has-addons is-centered">
        <span class="button"
          :class="{ 'is-success is-selected' : value.isActive }"
          @click="toggleActive(true)">
          <span class="icon is-small">
            <i class="mdi mdi-check" />
          </span>
          <span>Active</span>
        </span>
        <span class="button"
          :class="{ 'is-danger is-selected' : !value.isActive }"
          @click="toggleActive(false)">
          <span class="icon is-small">
            <i class="mdi mdi-close" />
          </span>
          <span>In-Active</span>
        </span>
      </div>

    </div>
  </div>
</template>

<script>
import QuoteService from '../quote/QuoteService'
import VueNumeric from '@/components/VueNumeric'

export default {
  name: 'SubJobStageDetail',
  inject: ['$vv'], //validation
  components: {
    VueNumeric
  },
  props: {
    value: null
  },
  data: () => {
    return {
      jobStages: []
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {

  },
  async created() {
    await this.fetchJobStages()
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    async fetchJobStages() {
      this.jobStages = await QuoteService.getJobStagesProto()
    },
    clear(input) {
      const inputEle = this.$refs[input]
      if (inputEle) {
        this.value[input] = ''
      }
    },
    toggleActive(isActive) {
      this.value.isActive = isActive
    }
  }
}
</script>